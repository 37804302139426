<template>
  <div>
    <Pane />

          <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
    <a-card class="container">

      <div class="title">
        基本情况
      </div>

        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="报告编号">
                <a-button block class='text ellipsis'>
                    {{ detail.number }}
                </a-button>
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="报告单位">
                  <a-button block  class=" text ellipsis">
                    {{ selectedCompany.name }}
                  </a-button>
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="报告时间">
                       <a-button block class='text ellipsis'>
                    {{ detail.reportTime }}
                </a-button>
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="接收人">
                        <a-button block class='text ellipsis'>
                    {{ detail.receiverName }}
                </a-button>
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

    <a-card class="container">
        <div class='title'>
              报告人
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="姓名">
                             <a-button block class='text ellipsis'>
                    {{ detail.reporterName }}
                </a-button>
       
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="联系电话">
                                  <a-button block class='text ellipsis'>
                    {{ detail.reporterMobile }}
                </a-button>
            </a-form-item>
          </a-col>
        </a-row>

    </a-card>

        <a-card class="container">
        <div class='title'>
              现场负责人
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="姓名">
                <a-button block class='text ellipsis'>
                    {{ detail.siteManagerName }}
                </a-button>
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="联系电话">
                          <a-button block class='text ellipsis'>
                    {{ detail.siteManagerMobile }}
                </a-button>
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

            <a-card class="container">
        <div class='title'>
              事故信息
          </div>
        <a-row>


        <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故类别">
                              <a-button block class='text ellipsis'>
                    <DataDictFinder dictType='acc_category' :dictValue="detail.accCategory" />
                </a-button>
            </a-form-item>
          </a-col>

            <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故级别">
                                <a-button block class='text ellipsis'>
                    <DataDictFinder dictType='acc_level' :dictValue="detail.accLevel" />
                </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故名称">
                              <a-button block class='text ellipsis'>
                                {{ detail.accName}}
                </a-button>
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故发生地点">
                                     <a-button block class='text ellipsis'>
                                {{ detail.accLocation}}
                </a-button>
            </a-form-item>
          </a-col>
                           <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故发生时间">

                          <a-button block class='text ellipsis'>
                                {{ detail.accTime}}
                </a-button>
            </a-form-item>
          </a-col>
                           <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故发生单位">
                                <a-button block class='text ellipsis'>
                                {{ detail.accOrg}}
                </a-button>
            </a-form-item>
          </a-col>


          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="事故经过简要描述" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <div class="textarea">
                    {{detail.accDesc}}
                </div>
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

        <a-card class="container">
        <div class='title'>
              人员伤亡情况
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="死亡人数">
              <a-input-number
                :value='detail.deadNum'
              />
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="失踪人数">
                      <a-input-number
                :value='detail.missingNum'
              />
            </a-form-item>
          </a-col>
                  <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="重伤人数">
                          <a-input-number
                :value='detail.injuredNum'
              />
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>


        <a-card class="container">
        <div class='title'>
              经济损失情况
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="直接经济损失(万)">
              <a-input-number
                :value='detail.directEconomicLoss'
              />
            </a-form-item>
          </a-col>

                <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="其他经济损失情况" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                     <div class="textarea">
                    {{detail.otherEconomicLoss}}
                </div>
            </a-form-item>
          </a-col>
    
    
        </a-row>
    </a-card>

            <a-card class="container">
        <div class='title'>
                其他
          </div>
        <a-row>

                <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="目前已经采取的控制措施" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                      <div class="textarea">
                    {{detail.controlMeasures}}
                </div>
            </a-form-item>
          </a-col>
                    <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="备注" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                             <div class="textarea">
                    {{detail.remark}}
                </div>
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

        <div class="footer center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>

  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import request from "@/api/request";
function fetchDetail({id}) {
  return request({
        url: "/office-service/quality/acc/report/queryDetail/" + id,
  })
}

export default {

  data() {
    return {
      form: this.$form.createForm(this),
      detail: {},
      selectedCompany: {},
      loading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    acc_level() {
      return this.findDataDict("acc_level");
    },

    acc_category() {
      return this.findDataDict("acc_category");
    },

  },
  mounted() {
    const { id } = this.$route.query;
    if (id !== this.detail.id) {
      fetchDetail({
        id
      }).then(res => {
        console.log('res',res)
        const detail = res || {}
        this.detail =detail;

        this.selectedCompany = {
          id: detail.deptId,
          name: detail.deptName,
        }
    
      })
    }
  },


};
</script>
  
  
<style lang="less" scoped>
.title {
  font-size: 14px;
  color: #1890ff;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 8px;
}
.footer {
  margin-top: 80px;
  margin-bottom: 80px;
}

.text {
    text-align: left;
}

.textarea {
    border: 1px solid #d9d9d9;
    padding: 6px 12px;
    min-height: 48px;
    border-radius: 2px;
    line-height: 1.8;
}

</style>